import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Sentry from "@sentry/browser";
import {
  fetchMFAPreference,
  fetchUserAttributes,
  getCurrentUser,
} from "aws-amplify/auth";

interface UserData {
  currentUser?: Sentry.User;
  isMfaEnabled?: boolean;
}

interface AuthState extends UserData {
  status: "pending" | "succeeded" | "failed";
}

export const fetchUserData = createAsyncThunk<UserData, void, { rejectValue: unknown }>(
  "auth/fetchUserData",
  async (_, { rejectWithValue }) => {
    try {
      const { username, userId } = await getCurrentUser();
      const { email } = await fetchUserAttributes();
      const { enabled } = await fetchMFAPreference();
      return {
        currentUser: { id: userId, email, username },
        isMfaEnabled: enabled?.includes("TOTP") ?? false,
      };
    } catch (error) {
      return rejectWithValue(
        error instanceof Error ? error.message : "Error fetching user data"
      );
    }
  }
);

const initialState: AuthState = {
  status: "pending",
  currentUser: undefined,
  isMfaEnabled: undefined,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMfaEnabled: (state, action: PayloadAction<boolean>) => {
      state.isMfaEnabled = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserData.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchUserData.fulfilled, (state, action: PayloadAction<UserData>) => {
        state.status = "succeeded";
        state.currentUser = action.payload.currentUser;
        state.isMfaEnabled = action.payload.isMfaEnabled;
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Actions
export const { setMfaEnabled } = authSlice.actions;

export default authSlice.reducer;
