import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faCheck,
  faLock,
  faPaperPlane,
  faPenToSquare,
  faRotate,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import sanitizeHtml from "sanitize-html";

import { getManagementPriority } from "../../rules/Recommendation";
import { getCommaSeparatedSnomedCodes } from "../../rules/SnomedCodes";
import { SubmissionType } from "../../schemas/ApiSchema";
import { dataService } from "../../services/data.service";
import { IFormContents } from "../../types/builder";
import { AuthorisedMicroReport } from "../../types/case";
import ManagementPriorityIndicator from "../atoms/ManagementPriorityIndicator";
import { TEST_ID_GO_TO_LIMS_LINK } from "./CaseNotFoundDialog";

export const TEST_ID_READY_TO_AUTHORISE_CHECKBOX = "ReadyToAuthoriseCheckbox";
export const TEST_ID_SEND_DRAFT_REPORT_OPTION = "SendDraftReportOption";
export const TEST_ID_SEND_FINAL_REPORT_OPTION = "SendFinalReportOption";
export const TEST_ID_SEND_REPORT_BUTTON = "SendReportButton";
export const TEST_ID_SEND_REPORT_ERROR = "SendReportError";
export const TEST_ID_SEND_DRAFT_REPORT_SUCCESS_MESSAGE = "SendDraftReportSuccessMessage";
export const TEST_ID_SEND_FINAL_REPORT_SUCCESS_MESSAGE = "SendFinalReportSuccessMessage";
export const TEST_ID_TEST_MODE_SUBMIT_REPORT_MESSAGE = "SubmitReportMessage";
export const TEST_ID_CLOSE_SAVE_REPORT_DIALOG_BUTTON = "CloseSaveReportDialogButton";

interface SaveReportDialogProps {
  testMode: boolean;
  labNumber: string;
  caseId: string;
  previousVersionId?: string;
  answers: IFormContents;
  setShowSaveReportDialog: (x: boolean) => void;
  setNextVersionId: (x: string) => void;
  setReadOnlyOnAuthorisation: (x: AuthorisedMicroReport) => void;
}

// To achieve type safety throughout component without including AUTOSAVE in submission dialog
type SubmissionTypeWithHtml = Exclude<SubmissionType, "AUTOSAVE">;

const SaveReportDialog = ({
  testMode,
  labNumber,
  caseId,
  answers,
  previousVersionId,
  setShowSaveReportDialog,
  setNextVersionId,
  setReadOnlyOnAuthorisation,
}: SaveReportDialogProps): JSX.Element => {
  const [busy, setBusy] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [submissionType, setSubmissionType] = useState<SubmissionTypeWithHtml>("DRAFT");
  const [authorisedFlag, setAuthorisedFlag] = useState<boolean>(false);

  // Reset the confirmation checkbox whenever the submission type changes
  useEffect(() => setAuthorisedFlag(false), [submissionType]);

  const managementPriority = getManagementPriority(answers);
  const snomedCodes = getCommaSeparatedSnomedCodes(answers);

  /**
   * Simplify the rendered HTML for the LIMS rich text editor using
   * https://github.com/apostrophecms/sanitize-html to reduce it to
   * a very limited set of HTML elements and CSS classes.
   */
  const getLimsHtml = (): string => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const pathkitHtml: HTMLElement = document.getElementById("reportHtml")!;
    return sanitizeHtml(pathkitHtml.innerHTML, {
      allowedTags: ["p", "b", "sup", "table", "tbody", "tr", "td"],
      allowedClasses: {
        // Tableau uses class="pathkit" to identify PathKit reports
        p: ["pathkit"],
        table: ["table", "table-bordered"],
      },
      transformTags: {
        div: "p",
        h3: "p",
        li: "p",
        span: "b",
        th: "td",
      },
    });
  };

  const saveMicroReport = async (): Promise<void> => {
    setError("");
    setSuccess(false);
    setBusy(true);
    const microHtml = getLimsHtml();
    const response = await dataService.saveMicroReport(
      labNumber,
      caseId,
      answers,
      submissionType,
      previousVersionId,
      microHtml,
      managementPriority,
      snomedCodes
    );
    setBusy(false);
    if (response.data) {
      setError("");
      setSuccess(true);
      setNextVersionId(response.data.versionId);
      if (submissionType === "AUTHORISED") {
        setReadOnlyOnAuthorisation({
          versionId: response.data.versionId,
          microHtml,
          publicationTimestamp: new Date().toISOString(),
          reasonForAmendment: answers.reasonForAmendment ?? null,
        });
      }
    } else {
      setSuccess(false);
      if (response.error.msg === "Request failed with status code 500") {
        // Friendly default error message
        setError(
          "We were unable to save to the LIMS. Please try again or contact support@cytedhealth.com for help."
        );
      } else {
        // Custom error message, e.g. "The micro report for case 00CYT00001 is already authorised"
        setError(response.error.msg);
      }
    }
  };

  const isSubmitButtonDisabled: boolean =
    busy || (submissionType === "AUTHORISED" && !authorisedFlag);

  type SubmissionTypeOption = {
    label: string;
    dataTestId: string;
    successMessageTestId: string;
    description: string;
    buttonIcon: IconDefinition;
    buttonLabel: string;
    limsUrl: string;
    successIcon: IconDefinition;
    successMessage: string;
  };

  const submissionTypeOptions: {
    [key in SubmissionTypeWithHtml]: SubmissionTypeOption;
  } = {
    DRAFT: {
      label: "Draft",
      dataTestId: TEST_ID_SEND_DRAFT_REPORT_OPTION,
      description:
        "You will still be able to change your answers later, e.g. following a second opinion.",
      buttonIcon: faPenToSquare,
      buttonLabel: "Save draft",
      // No caseId in testMode
      limsUrl:
        import.meta.env.VITE_APP_LIMS_URL +
        (caseId ? `/Casebook/Micro?CaseID=${caseId}` : "/Home/Index"),
      successIcon: faCheck,
      successMessage:
        "Your draft report has been saved. You can view it in the LIMS or continue making changes.",
      successMessageTestId: TEST_ID_SEND_DRAFT_REPORT_SUCCESS_MESSAGE,
    },
    AUTHORISED: {
      label: "Final",
      dataTestId: TEST_ID_SEND_FINAL_REPORT_OPTION,
      description:
        "Your report will be authorised and sent to the referring clinician. Subsequent amendments must be arranged with the clinical and lab teams.",
      buttonIcon: faPaperPlane,
      buttonLabel: "Authorise and send",
      limsUrl: import.meta.env.VITE_APP_LIMS_URL + "/Home/Index",
      successIcon: faLock,
      successMessage:
        "Your report has been authorised and sent to the referring clinician. Subsequent amendments must be arranged with the clinical and lab teams.",
      successMessageTestId: TEST_ID_SEND_FINAL_REPORT_SUCCESS_MESSAGE,
    },
  };

  const handleClose = () => !busy && setShowSaveReportDialog(false);

  const ReadyToAuthorise = (): JSX.Element => {
    return (
      <div className="notification is-warning">
        <div className="columns is-mobile is-variable is-2">
          <div className="column is-narrow">
            <input
              type="checkbox"
              id="authorisedFlag"
              data-testid={TEST_ID_READY_TO_AUTHORISE_CHECKBOX}
              checked={authorisedFlag}
              disabled={busy || success}
              onChange={(e) => setAuthorisedFlag(e.target.checked)}
            />
          </div>
          <label
            htmlFor="authorisedFlag"
            className="column is-clickable has-text-weight-bold"
          >
            I am ready to authorise and send this report
          </label>
        </div>
      </div>
    );
  };

  const SuccessDialog = (): JSX.Element => {
    return (
      <div
        className="notification is-success"
        data-testid={submissionTypeOptions[submissionType].successMessageTestId}
      >
        <div className="columns is-mobile is-variable is-2">
          <div className="column is-narrow">
            <FontAwesomeIcon
              icon={submissionTypeOptions[submissionType].successIcon}
              size="lg"
            />
          </div>
          <p className="column">{submissionTypeOptions[submissionType].successMessage}</p>
        </div>
      </div>
    );
  };

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={handleClose}></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box has-background-grey-lighter">
          <div className="content">
            <h3 className="title is-4">Submit report</h3>
            <h4 className="title is-5 mb-4">
              {!testMode ? `Micro report for ${labNumber}` : "Not available"}
            </h4>
            {testMode && (
              <p data-testid={TEST_ID_TEST_MODE_SUBMIT_REPORT_MESSAGE}>
                Find a case in the LIMS and return to PathKit using the link provided
                there.
              </p>
            )}

            {!testMode && !success && (
              <>
                <ManagementPriorityIndicator priority={managementPriority} />
                <form className="mb-5">
                  {Object.entries(submissionTypeOptions).map(
                    ([status, { label, dataTestId, description }]) => {
                      return (
                        <div className="columns is-mobile is-variable is-2" key={status}>
                          <div className="column is-narrow">
                            <input
                              type="radio"
                              id={status}
                              value={status}
                              name="submissionType"
                              data-testid={dataTestId}
                              checked={submissionType === status}
                              disabled={busy || success}
                              onChange={(e) =>
                                setSubmissionType(
                                  e.target.value as SubmissionTypeWithHtml
                                )
                              }
                            />
                          </div>
                          <label htmlFor={status} className="column is-clickable">
                            <b className="is-block mb-1">{label}</b>
                            {description}
                          </label>
                        </div>
                      );
                    }
                  )}
                  {submissionType === "AUTHORISED" && <ReadyToAuthorise />}
                </form>
              </>
            )}
            {success && <SuccessDialog />}
            <div className="buttons">
              {success || testMode ? (
                <a
                  href={submissionTypeOptions[submissionType].limsUrl}
                  data-testid={TEST_ID_GO_TO_LIMS_LINK}
                  className="button is-primary"
                >
                  Go to LIMS
                </a>
              ) : (
                <button
                  className="button is-primary"
                  disabled={isSubmitButtonDisabled}
                  onClick={saveMicroReport}
                  data-testid={TEST_ID_SEND_REPORT_BUTTON}
                >
                  <FontAwesomeIcon
                    spin={busy}
                    icon={
                      busy ? faRotate : submissionTypeOptions[submissionType].buttonIcon
                    }
                    className="mr-2"
                  />
                  {busy
                    ? "Please wait..."
                    : submissionTypeOptions[submissionType].buttonLabel}
                </button>
              )}
              <button
                className="button is-light"
                disabled={busy}
                onClick={handleClose}
                data-testid={TEST_ID_CLOSE_SAVE_REPORT_DIALOG_BUTTON}
              >
                {success ? "Close" : "Cancel"}
              </button>
            </div>

            {!!error && (
              <p
                className="notification is-danger"
                data-testid={TEST_ID_SEND_REPORT_ERROR}
              >
                {error}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SaveReportDialog;
