import React from "react";

const Placeholder = (): JSX.Element => {
  return (
    <>
      <p className="block has-background-grey-lighter p-2"></p>
      <p className="block has-background-grey-lighter p-2" style={{ width: "65%" }}></p>
    </>
  );
};

export default Placeholder;
