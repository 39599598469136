import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faCheck, faSpinner, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export const TEST_ID_AUTOSAVE_STATUS_ICON = "AutosaveStatusIcon";
export const TEST_ID_AUTOSAVE_STATUS_LABEL = "AutosaveStatusLabel";

interface AutosaveStatusProps {
  status?: AutosaveStatus;
}

export enum AutosaveStatus {
  SAVED = "Saved",
  PENDING = "Pending",
  SAVING = "Saving",
  ERROR = "Error",
}

type AutosaveStatusMap = {
  [key in AutosaveStatus]: {
    icon: IconDefinition;
    spin?: boolean;
    label: string;
  };
};

export const autosaveStatusMap: AutosaveStatusMap = {
  [AutosaveStatus.SAVED]: {
    icon: faCheck,
    label: "Progress saved",
  },
  [AutosaveStatus.PENDING]: {
    icon: faSpinner,
    spin: true,
    label: "Saving changes",
  },
  [AutosaveStatus.SAVING]: {
    icon: faSpinner,
    spin: true,
    label: "Saving changes",
  },
  [AutosaveStatus.ERROR]: {
    icon: faXmark,
    label: "Saving failed",
  },
};

const AutosaveIndicator = ({ status }: AutosaveStatusProps): JSX.Element | null => {
  if (!status) return null;

  const { icon, spin, label } = autosaveStatusMap[status];
  return (
    <span className="tag is-rounded" data-testid={TEST_ID_AUTOSAVE_STATUS_LABEL}>
      <FontAwesomeIcon
        icon={icon}
        spin={spin}
        className="mr-1"
        data-testid={TEST_ID_AUTOSAVE_STATUS_ICON}
      />
      {label}
    </span>
  );
};

export default AutosaveIndicator;
