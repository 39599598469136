import { differenceInYears, format } from "date-fns";
import React from "react";

import { ICaseData } from "../../types/case";

export const TEST_ID_PATIENT_INFORMATION_SECTION = "PatientInformationSection";
export const TEST_ID_PATIENT_INFORMATION_HEADING = "PatientInformationHeading";
export const TEST_ID_PATIENT_DATE_OF_BIRTH = "PatientDateOfBirth";
export const TEST_ID_PATIENT_IDENTIFIER_ALTERNATIVE = "PatientIdentifierAlternative";

interface PatientInformationProps {
  caseData: ICaseData;
}

const PatientInformation = ({ caseData }: PatientInformationProps): JSX.Element => {
  const {
    patientSex,
    patientDateOfBirthIsYearOnly,
    patientDateOfBirth,
    patientIdentifier,
    patientIdentifierAlternative,
    procedureDate,
    recordNumber,
  } = caseData;

  // Calculate age when full date of birth is known
  const ageAtProcedure: string = !patientDateOfBirthIsYearOnly
    ? differenceInYears(procedureDate, patientDateOfBirth).toString()
    : "";

  // Display age in heading when available
  const sectionHeading: string = ageAtProcedure
    ? patientSex + ", " + ageAtProcedure
    : patientSex;

  // Display year of birth when full date of birth is unknown
  const dateOfBirth: string = !patientDateOfBirthIsYearOnly
    ? format(patientDateOfBirth, "d MMM yyyy")
    : patientDateOfBirth;

  return (
    <section data-testid={TEST_ID_PATIENT_INFORMATION_SECTION}>
      <h4 className="title is-6 mb-4" data-testid={TEST_ID_PATIENT_INFORMATION_HEADING}>
        {sectionHeading}
        {!!ageAtProcedure && <small className="is-size-7"> (on procedure date)</small>}
      </h4>
      <dl>
        <div className="cy-data-list__item" data-testid={TEST_ID_PATIENT_DATE_OF_BIRTH}>
          <dt>{patientDateOfBirthIsYearOnly ? "Year" : "Date"} of birth</dt>
          <dd>{dateOfBirth}</dd>
        </div>
        <div className="cy-data-list__item">
          <dt>NHS/CHI number</dt>
          <dd>{patientIdentifier}</dd>
        </div>
        {!!patientIdentifierAlternative && (
          <div
            className="cy-data-list__item"
            data-testid={TEST_ID_PATIENT_IDENTIFIER_ALTERNATIVE}
          >
            <dt>Other medical record number</dt>
            <dd>{patientIdentifierAlternative}</dd>
          </div>
        )}
        <div className="cy-data-list__item">
          <dt>Cell preservation kit ID</dt>
          <dd>{recordNumber}</dd>
        </div>
      </dl>
    </section>
  );
};

export default PatientInformation;
