import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, ScrollRestoration } from "react-router-dom";

import Navbar from "../components/navigation/Navbar";
import { AppDispatch } from "../store";
import { fetchUserData } from "../store/authSlice";

const Root = (): JSX.Element => {
  // Redux
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchUserData());
  }, []);

  return (
    <>
      <Navbar />
      <main className="section pt-0">
        <Outlet />
      </main>
      <ScrollRestoration />
    </>
  );
};

export default Root;
