import { MAX_PRAGUE_MEASUREMENT } from "../components/questions/PragueClassification";

const isValidCaseId = (caseId: string): boolean => {
  // Case ID in the LIMS is an int
  const caseIdAsNumber = Number(caseId);
  return Number.isInteger(caseIdAsNumber) && caseIdAsNumber > 0;
};

const isValidLabNumber = (labNumber: string): boolean => {
  return !!labNumber?.match(/^\d{2}CYT\d{5}$/);
};

const isValidBarrettsSegmentLength = (segmentLength: number): boolean => {
  return (
    Number.isInteger(segmentLength) &&
    segmentLength >= 0 &&
    segmentLength <= MAX_PRAGUE_MEASUREMENT // 20
  );
};

const isValidPragueClassification = (
  circumferential?: number,
  maximal?: number
): boolean => {
  return (
    circumferential !== undefined &&
    maximal !== undefined &&
    isValidBarrettsSegmentLength(circumferential) &&
    isValidBarrettsSegmentLength(maximal) &&
    circumferential <= maximal
  );
};

export { isValidCaseId, isValidLabNumber, isValidPragueClassification };
