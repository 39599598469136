export enum ReasonForAmendment {
  INTERNAL_AUDIT = "CytedInternalAudit",
  CUSTOMER_REQUEST = "CustomerRequestedAudit",
  NEW_INFORMATION_FROM_CUSTOMER = "NewInformationFromCustomer",
  NONE_OF_THE_ABOVE = "PathologistCorrection",
}

export enum KnownBarretts {
  NO = "NoKnownBarrettsHistory",
  YES = "KnownBarrettsHistory",
}

export enum AdequateSample {
  YES = "AdequateSample",
  NO = "InadequateSample",
}

export enum P53Stain {
  YES = "HasP53Stain",
  NO = "NoP53Stain",
}

export enum InadequateSampleReason {
  INSUFFICIENT = "InsufficientSquamousCells",
  FOOD = "FoodMaterialContamination",
}

export enum SquamousAbnormality {
  NO = "NoSquamousAbnormality",
  YES = "SquamousAbnormality",
  INADEQUATE = "InadequateSquamousCellularity",
}

export enum SquamousAtypia {
  NO = "NoSquamousAtypia",
  UNCERTAIN_SIGNIFICANCE = "SquamousAtypiaUncertain",
  SUSPECTED_DYSPLASIA = "SquamousAtypiaSuspected",
}

export enum SquamousP53Staining {
  NORMAL = "SquamousP53Normal",
  EQUIVOCAL = "SquamousP53Equivocal",
  ABERRANT = "SquamousP53Aberrant",
}

export enum ColumnarGroups {
  FIVEORMORE = "FiveOrMoreColumnarGroups",
  ONETOFOUR = "OneToFourColumnarGroups",
  NONE = "NoColumnarGroups",
}

export enum Tff3Staining {
  NEGATIVE = "TFF3Negative",
  EQUIVOCAL = "TFF3Equivocal",
  POSITIVE = "TFF3Positive",
}

export enum ColumnarAtypia {
  NO = "NoColumnarAtypia",
  UNCERTAIN_SIGNIFICANCE = "ColumnarAtypiaUncertainSignificance",
  SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE = "ColumnarAtypiaDysplasiaSuspectedButDifficultToGrade",
  SUSPECTED_HIGH_GRADE_DYSPLASIA = "ColumnarAtypiaSuspectedHighGradeDysplasia",
  HIGH_GRADE_DYSPLASIA = "ColumnarAtypiaHighGradeDysplasia",
  AT_LEAST_HIGH_GRADE_DYSPLASIA = "ColumnarAtypiaAtLeastHighGradeDysplasia",
}

export enum ColumnarP53Staining {
  NORMAL = "ColumnarP53Normal",
  EQUIVOCAL = "ColumnarP53Equivocal",
  ABERRANT = "ColumnarP53Aberrant",
}

export enum OtherAbnormalities {
  ULCERATION = "EvidenceOfUlceration",
}
