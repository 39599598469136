import { signOut } from "aws-amplify/auth";
import { MouseEvent } from "react";

import { SITE_TITLE } from "./strings";

export const handleSignOut = async (e: MouseEvent) => {
  e.preventDefault();
  try {
    await signOut();
    document.title = SITE_TITLE;
  } catch (error) {
    console.error("Error signing out: ", error);
  }
};
