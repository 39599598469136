import React from "react";

export const TEST_ID_READ_ONLY_MICRO_REPORT = "ReadOnlyMicroReport";

interface ReportSubmissionProps {
  microHtml: string;
  versionId?: string;
}

const ReportSubmission = ({
  microHtml,
  versionId,
}: ReportSubmissionProps): JSX.Element => {
  return (
    <div
      className="cy-report-readonly"
      data-testid={TEST_ID_READ_ONLY_MICRO_REPORT + (versionId ? versionId : "")}
      dangerouslySetInnerHTML={{ __html: microHtml }}
    />
  );
};

export default ReportSubmission;
