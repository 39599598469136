import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEvent, useState } from "react";

import {
  IFormContents,
  IQuestion,
  QuestionGroupId,
  QuestionGroupType,
} from "../../types/builder";
import { ICaseData } from "../../types/case";
import CheckboxGroup from "./CheckboxGroup";
import ImportPragueClassification from "./ImportPragueClassification";
import RadioGroup from "./RadioGroup";

interface FieldsetProps {
  id: QuestionGroupId;
  type: QuestionGroupType;
  legend: string;
  expanded: boolean;
  caseData?: ICaseData;
  questions: IQuestion[];
  answers: IFormContents;
  updatePragueClassification: (c?: string, m?: string) => void;
}

const Fieldset = ({
  id,
  type,
  legend,
  expanded,
  caseData,
  questions,
  answers,
  updatePragueClassification,
}: FieldsetProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = useState<boolean>(expanded);

  const toggleExpanded = (e: MouseEvent) => {
    e.preventDefault();
    setIsExpanded(!isExpanded);
  };

  const visibleQuestions: IQuestion[] = questions.filter(
    (question: IQuestion) => question.visible
  );
  const answeredQuestions: IQuestion[] = visibleQuestions.filter(
    (question: IQuestion) => !!question.selected
  );
  const isComplete: boolean = answeredQuestions.length === visibleQuestions.length;

  const ProgressTag = (): JSX.Element => {
    return (
      <span
        className={classNames({
          "tag is-rounded ml-3": true,
          "is-link is-light": type === QuestionGroupType.CHECKBOX,
          "is-danger": type === QuestionGroupType.RADIO && !isComplete,
          "is-success": type === QuestionGroupType.RADIO && isComplete,
        })}
      >
        {type === QuestionGroupType.RADIO &&
          `${answeredQuestions.length} of ${visibleQuestions.length}`}
        {type === QuestionGroupType.CHECKBOX && "Optional"}
      </span>
    );
  };

  return (
    <fieldset
      className={classNames({
        "mb-4": true,
        "is-expanded": isExpanded,
      })}
      data-testid={`${id}Group`}
    >
      <legend className="title is-4 mb-5">
        <a
          href=""
          role="button"
          data-testid={`${id}Toggle`}
          className="is-flex is-align-items-center has-text-grey-darker"
          onClick={toggleExpanded}
        >
          <FontAwesomeIcon
            icon={faCaretRight}
            className={classNames({
              "icon mr-2 is-flex-shrink-0": true,
              "fa-rotate-90": isExpanded,
            })}
            style={{ transition: "transform .15s" }}
          />
          <span>{legend}</span>
          <ProgressTag />
        </a>
      </legend>
      {isExpanded && (
        <>
          {id === QuestionGroupId.PRAGUE_CLASSIFICATION && (
            <ImportPragueClassification
              circumferential={caseData?.circumferentialLength}
              maximal={caseData?.maximalLength}
              updatePragueClassification={updatePragueClassification}
            />
          )}
          {visibleQuestions.map((question: IQuestion) => {
            if (type === QuestionGroupType.RADIO) {
              return (
                <RadioGroup key={question.name} question={question} answers={answers} />
              );
            } else if (type === QuestionGroupType.CHECKBOX) {
              return <CheckboxGroup key={question.name} question={question} />;
            }
          })}
        </>
      )}
    </fieldset>
  );
};

export default Fieldset;
