import { Authenticator } from "@aws-amplify/ui-react";
import * as Sentry from "@sentry/react";
import { Amplify } from "aws-amplify";
import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import components from "./components/authenticator/Components";
import formFields from "./components/authenticator/FormFields";
import ReportBuilder from "./routes/ReportBuilder";
import Root from "./routes/Root";
import UserProfile from "./routes/UserProfile";
import { store } from "./store";

function NotFound() {
  return <h1>Not Found</h1>;
}

Sentry.init({
  dsn: "https://78fcf35948c44579bd2c7fd708ff95a4@o429717.ingest.sentry.io/4504616905211904",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api.*pathkit\.cytedhealth\.com/],
  environment: import.meta.env.VITE_APP_ENVIRONMENT ?? "local-development",

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: import.meta.env.VITE_APP_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_APP_USER_POOL_APP_CLIENT_ID,
    },
  },
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <ReportBuilder />,
      },
      {
        path: "profile",
        element: <UserProfile />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <Authenticator
      hideSignUp
      className="mt-6"
      components={components}
      formFields={formFields}
    >
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </Authenticator>
  </React.StrictMode>
);
