import React from "react";

import {
  AdequateSample,
  ColumnarAtypia,
  ColumnarGroups,
  ColumnarP53Staining,
  InadequateSampleReason,
  KnownBarretts,
  OtherAbnormalities,
  P53Stain,
  ReasonForAmendment,
  SquamousAbnormality,
  SquamousAtypia,
  SquamousP53Staining,
  Tff3Staining,
} from "./answers";

export enum QuestionGroupId {
  REASON_FOR_AMENDMENT = "reasonForAmendment",
  INITIAL_QUESTIONS = "initialQuestions",
  PRAGUE_CLASSIFICATION = "pragueClassification",
  SQUAMOUS_CELLS = "squamousCells",
  COLUMNAR_CELLS = "columnarCells",
  OTHER_ABNORMALITIES = "otherAbnormalities",
}

export enum QuestionGroupType {
  RADIO = "Radio",
  CHECKBOX = "Checkbox",
}

export enum ManagementPriority {
  URGENT = "ManagementPriorityUrgent",
  INADEQUATE = "ManagementPriorityInadequate",
  ROUTINE = "ManagementPriorityRoutine",
  NORMAL = "ManagementPriorityNormal",
}

export interface IQuestionGroup {
  id: QuestionGroupId;
  type: QuestionGroupType;
  title: string;
  visible: boolean;
  expanded: boolean;
  questions: IQuestion[];
}

export interface IQuestion {
  name: string;
  title: string;
  options: IAnswer[];
  visible: boolean;
  optional?: boolean;
  selected?: string | string[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface IAnswer {
  disabled?: boolean;
  hidden?: boolean;
  label: string;
  value: string;
}

export interface IFormContents {
  reasonForAmendment?: ReasonForAmendment;
  knownBarretts?: KnownBarretts;
  adequateSample?: AdequateSample;
  hasP53Stain?: P53Stain;
  inadequateSampleReason?: InadequateSampleReason;
  pragueCircumferential?: string;
  pragueMaximal?: string;
  squamousAbnormality?: SquamousAbnormality;
  squamousAtypia?: SquamousAtypia;
  squamousP53Staining?: SquamousP53Staining;
  columnarGroups?: ColumnarGroups;
  tff3Staining?: Tff3Staining;
  tff3PositiveGroupsSlideSectionOne?: string;
  tff3PositiveGroupsSlideSectionTwo?: string;
  columnarAtypia?: ColumnarAtypia;
  columnarP53Staining?: ColumnarP53Staining;
  otherAbnormalities?: OtherAbnormalities[];
}

export interface IConditionalContent {
  conditions: Partial<
    Record<keyof IFormContents, Array<string | OtherAbnormalities[] | undefined>>
  >;
}
