import { format } from "date-fns";
import React from "react";
import { AuthorisedMicroReport } from "src/types/case";

import { ReasonForAmendment } from "../../types/answers";
import { IConditionalContent, IFormContents } from "../../types/builder";
import Placeholder from "./Placeholder";
import SectionHeading from "./SectionHeading";
import SectionSpacer from "./SectionSpacer";

export const TEST_ID_REPORT_PREVIEW_AMENDMENT = "ReportPreviewAmendment";

interface AmendmentPhrase extends IConditionalContent {
  phrase: string;
}

interface AmendmentProps {
  answers: IFormContents;
  isMicroAmendment: boolean;
  previousReport?: AuthorisedMicroReport;
}

const Amendment = ({
  answers: { reasonForAmendment },
  isMicroAmendment,
  previousReport,
}: AmendmentProps): JSX.Element | null => {
  const amendmentPhrases: AmendmentPhrase[] = [
    {
      phrase: "This case was reviewed as part of Cyted’s regular audit processes.",
      conditions: {
        reasonForAmendment: [ReasonForAmendment.INTERNAL_AUDIT],
      },
    },
    {
      phrase: "This case was reviewed following a request from the customer.",
      conditions: {
        reasonForAmendment: [ReasonForAmendment.CUSTOMER_REQUEST],
      },
    },
    {
      phrase:
        "This case was reviewed following additional information received from the customer.",
      conditions: {
        reasonForAmendment: [ReasonForAmendment.NEW_INFORMATION_FROM_CUSTOMER],
      },
    },
    {
      phrase: "This report was amended following a review by the reporting pathologist.",
      conditions: {
        reasonForAmendment: [ReasonForAmendment.NONE_OF_THE_ABOVE],
      },
    },
  ];

  const matchedAmendmentPhrase: AmendmentPhrase | undefined = amendmentPhrases.find(
    (phrase: AmendmentPhrase) => {
      return Object.values(phrase.conditions).every((condition) => {
        return condition?.includes(reasonForAmendment) ?? false;
      });
    }
  );

  if (!isMicroAmendment || !previousReport) return null;

  const { publicationTimestamp, versionId } = previousReport;
  const prevReportDate = format(publicationTimestamp, "dd MMM yyyy HH:mm");
  const prevReportId = versionId.substring(0, 7);

  return (
    <section data-testid={TEST_ID_REPORT_PREVIEW_AMENDMENT}>
      <SectionHeading>This is an amended report</SectionHeading>
      {matchedAmendmentPhrase ? (
        <>
          <p>
            {matchedAmendmentPhrase.phrase} The amended report replaces the previous
            report which was issued on {prevReportDate} (Cyted ref: {prevReportId}).
          </p>
          <p>
            <b>Please disregard any previous reports relating to this case.</b>
          </p>
        </>
      ) : (
        <Placeholder />
      )}
      <SectionSpacer />
    </section>
  );
};

export default Amendment;
