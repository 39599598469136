import {
  LONG_SEGMENT_CIRCUMFERENTIAL_THRESHOLD,
  LONG_SEGMENT_MAXIMAL_THRESHOLD,
  MAX_PRAGUE_MEASUREMENT,
  PRAGUE_CLASSIFICATION_NOT_PROVIDED,
  SCANT_COLUMNAR_EPITHELIUM_THRESHOLD,
} from "../components/questions/PragueClassification";
import { getNumberArray } from "../helpers/numbers";
import {
  ColumnarAtypia,
  ColumnarGroups,
  ColumnarP53Staining,
  InadequateSampleReason,
  KnownBarretts,
  SquamousAtypia,
  SquamousP53Staining,
  Tff3Staining,
} from "../types/answers";
import { IConditionalContent, IFormContents, ManagementPriority } from "../types/builder";

export interface ManagementRecommendation extends IConditionalContent {
  phrase: string;
  priority: ManagementPriority;
}

const managementRecommendations: ManagementRecommendation[] = [
  // Squamous abnormality
  {
    priority: ManagementPriority.URGENT,
    phrase: "Urgent endoscopic assessment with multiple biopsies is recommended.",
    conditions: {
      squamousAtypia: [
        SquamousAtypia.UNCERTAIN_SIGNIFICANCE,
        SquamousAtypia.SUSPECTED_DYSPLASIA,
      ],
    },
  },
  {
    priority: ManagementPriority.URGENT,
    phrase: "Urgent endoscopic assessment with multiple biopsies is recommended.",
    conditions: {
      squamousP53Staining: [SquamousP53Staining.EQUIVOCAL, SquamousP53Staining.ABERRANT],
    },
  },
  // Columnar atypia
  {
    priority: ManagementPriority.URGENT,
    phrase: "Urgent endoscopic assessment with multiple biopsies is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarAtypia: [
        ColumnarAtypia.UNCERTAIN_SIGNIFICANCE,
        ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE,
        ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA,
        ColumnarAtypia.HIGH_GRADE_DYSPLASIA,
        ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA,
      ],
    },
  },
  {
    priority: ManagementPriority.URGENT,
    phrase:
      "Urgent endoscopic assessment including multiple biopsies for Barrett’s oesophagus or gastric intestinal metaplasia is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      columnarAtypia: [
        ColumnarAtypia.UNCERTAIN_SIGNIFICANCE,
        ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE,
        ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA,
        ColumnarAtypia.HIGH_GRADE_DYSPLASIA,
        ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA,
      ],
    },
  },
  // Columnar cells
  {
    priority: ManagementPriority.URGENT,
    phrase: "Urgent endoscopic assessment with multiple biopsies is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL, ColumnarP53Staining.ABERRANT],
    },
  },
  {
    priority: ManagementPriority.URGENT,
    phrase: "Urgent endoscopic assessment with multiple biopsies is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.EQUIVOCAL],
      columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL],
    },
  },
  {
    priority: ManagementPriority.URGENT,
    phrase: "Urgent endoscopic assessment with multiple biopsies is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.NEGATIVE],
      columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL, ColumnarP53Staining.ABERRANT],
    },
  },
  {
    priority: ManagementPriority.URGENT,
    phrase:
      "Urgent endoscopic assessment including multiple biopsies for Barrett’s oesophagus or gastric intestinal metaplasia is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.POSITIVE],
      columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL, ColumnarP53Staining.ABERRANT],
    },
  },
  {
    priority: ManagementPriority.URGENT,
    phrase:
      "Urgent endoscopic assessment including multiple biopsies for Barrett’s oesophagus or gastric intestinal metaplasia is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.EQUIVOCAL],
      columnarP53Staining: [ColumnarP53Staining.ABERRANT],
    },
  },
  {
    priority: ManagementPriority.ROUTINE,
    phrase:
      "Non-urgent endoscopic assessment including biopsies for Barrett’s oesophagus or gastric intestinal metaplasia is recommended.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.POSITIVE],
      columnarP53Staining: [ColumnarP53Staining.NORMAL],
    },
  },
  // Long segments of Barrett’s (insufficient for diagnosis)
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "As there is a long (or unreported) Barrett’s segment (C = 4cm or more and/or M = 6cm or more) and no intestinal metaplasia is seen, it is likely that this is not a sufficient sample for surveillance purposes. A repeat test should be considered or consider endoscopy if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      pragueCircumferential: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(LONG_SEGMENT_CIRCUMFERENTIAL_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
      columnarGroups: [ColumnarGroups.FIVEORMORE],
      tff3Staining: [Tff3Staining.NEGATIVE, Tff3Staining.EQUIVOCAL],
    },
  },
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "As there is a long (or unreported) Barrett’s segment (C = 4cm or more and/or M = 6cm or more) and no intestinal metaplasia is seen, it is likely that this is not a sufficient sample for surveillance purposes. A repeat test should be considered or consider endoscopy if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      pragueMaximal: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(LONG_SEGMENT_MAXIMAL_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
      columnarGroups: [ColumnarGroups.FIVEORMORE],
      tff3Staining: [Tff3Staining.NEGATIVE, Tff3Staining.EQUIVOCAL],
    },
  },
  // Scant columnar epithelium (insufficient for diagnosis)
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "Repeat testing is recommended – consider endoscopy if the test was performed for a patient with symptoms or if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarGroups: [ColumnarGroups.ONETOFOUR],
      pragueCircumferential: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(SCANT_COLUMNAR_EPITHELIUM_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
    },
  },
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "Repeat testing is recommended – consider endoscopy if the test was performed for a patient with symptoms or if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarGroups: [ColumnarGroups.ONETOFOUR],
      pragueMaximal: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(SCANT_COLUMNAR_EPITHELIUM_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
    },
  },
  {
    priority: ManagementPriority.ROUTINE,
    phrase:
      "Non-urgent endoscopic assessment is recommended at intervals in line with the current guidelines for Barrett’s oesophagus.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      tff3Staining: [Tff3Staining.EQUIVOCAL, Tff3Staining.POSITIVE],
    },
  },
  {
    priority: ManagementPriority.ROUTINE,
    phrase:
      "Follow up is recommended at intervals in line with the current guidelines for Barrett’s oesophagus.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      tff3Staining: [Tff3Staining.NEGATIVE],
    },
  },
  {
    priority: ManagementPriority.NORMAL,
    phrase:
      "If there are clinical concerns or if gastric pathology is a possibility, further evaluation with endoscopy should be considered.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.NEGATIVE],
    },
  },
  // Repeat testing
  {
    priority: ManagementPriority.ROUTINE,
    phrase:
      "A repeat test is recommended or proceed to endoscopy if there are clinical symptoms or if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      tff3Staining: [Tff3Staining.EQUIVOCAL],
    },
  },
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "If the patient has risk factors for Barrett’s oesophagus then repeat testing is recommended in line with BSG guidelines. Endoscopy should be considered if there are any clinical concerns or if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.NO],
      columnarGroups: [ColumnarGroups.NONE],
    },
  },
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "Repeat testing is recommended – consider endoscopy if there are any clinical concerns or if there was a previous insufficient sample.",
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarGroups: [ColumnarGroups.NONE],
    },
  },
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "Repeat testing is recommended if clinically indicated – consider endoscopy if the test was performed for a patient with symptoms or if there was a previous insufficient sample.",
    conditions: {
      inadequateSampleReason: [InadequateSampleReason.INSUFFICIENT],
    },
  },
  {
    priority: ManagementPriority.INADEQUATE,
    phrase:
      "Repeat testing (after an appropriate fasting period) is recommended if clinically indicated – consider endoscopy if the test was performed for a patient with symptoms or if there was a previous insufficient sample.",
    conditions: {
      inadequateSampleReason: [InadequateSampleReason.FOOD],
    },
  },
];

const getRecommendation = (
  answers: IFormContents
): ManagementRecommendation | undefined => {
  // Find the first management recommendation that matches the user's answers
  const managementRecommendation: ManagementRecommendation | undefined =
    managementRecommendations.find((recommendation: ManagementRecommendation) => {
      return Object.entries(recommendation.conditions).every(([key, condition]) => {
        const answer = answers[key as keyof typeof answers];
        return condition?.includes(answer) ?? false;
      });
    });

  return managementRecommendation;
};

const getManagementRecommendation = (answers: IFormContents): string | undefined => {
  return getRecommendation(answers)?.phrase;
};

const getManagementPriority = (
  answers: IFormContents
): ManagementPriority | undefined => {
  const priority = getRecommendation(answers)?.priority;

  // Override 'Normal' and upgrade to 'Routine' if there are any other abnormalities
  if (priority === ManagementPriority.NORMAL && !!answers.otherAbnormalities?.length) {
    return ManagementPriority.ROUTINE;
  } else {
    return priority;
  }
};

export { getManagementRecommendation, getManagementPriority };
