import classNames from "classnames";
import React from "react";

export const TEST_ID_REPORT_READY_INDICATOR = "ReportReadyIndicator";

const ReadyIndicator = ({ isReady }: { isReady: boolean }): JSX.Element => {
  return (
    <span
      className={classNames({
        "tag is-rounded": true,
        "is-danger": !isReady,
        "is-success": isReady,
      })}
      data-testid={TEST_ID_REPORT_READY_INDICATOR}
    >
      {isReady ? "Ready" : "Incomplete"}
    </span>
  );
};

export default ReadyIndicator;
