import React from "react";

export const TEST_ID_CASE_DIALOG_HEADING = "CaseDialogHeading";
export const TEST_ID_GO_TO_LIMS_LINK = "GoToLimsLink";
export const TEST_ID_TRY_PATHKIT_BUTTON = "TryPathKitButton";

interface CaseNotFoundDialogProps {
  caseNotFound: boolean;
  setShowCaseNotFoundDialog: (x: boolean) => void;
}

const CaseNotFoundDialog = ({
  caseNotFound,
  setShowCaseNotFoundDialog,
}: CaseNotFoundDialogProps): JSX.Element => {
  return (
    <div className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-content" style={{ maxWidth: 500 }}>
        <div className="box content has-background-grey-lighter">
          <h3 className="title is-4" data-testid={TEST_ID_CASE_DIALOG_HEADING}>
            {caseNotFound ? "Case not found" : "Next steps"}
          </h3>
          <div className="columns">
            {caseNotFound ? (
              <div className="column">
                The link you followed may be broken, or the case may have been authorised,
                closed, moved or deleted.
              </div>
            ) : (
              <div className="column">
                Find a case in the LIMS and return to PathKit using the link provided
                there.
              </div>
            )}
            <div className="column is-narrow">
              <a
                href={import.meta.env.VITE_APP_LIMS_URL + "/Home/Index"}
                className="button is-primary"
                data-testid={TEST_ID_GO_TO_LIMS_LINK}
              >
                Go to LIMS
              </a>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              Try PathKit’s Micro Report Builder - you will only see some of PathKit’s
              features and won’t be able to save.
            </div>
            <div className="column is-narrow">
              <button
                type="button"
                className="button"
                onClick={() => setShowCaseNotFoundDialog(false)}
                data-testid={TEST_ID_TRY_PATHKIT_BUTTON}
              >
                Try PathKit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseNotFoundDialog;
