import { IFormContents } from "../types/builder";
import { AuthorisedMicroReport, CaseType, ICaseData } from "../types/case";

/**
 * This version number tracks changes to the API or JSON schemas,
 * e.g. those arising from changes to questions and/or answers in the UI.
 *
 * IF UPDATING THIS VALUE YOU MUST ALSO UPDATE THE VALUE IN LABKIT
 *
 * 1    First release
 * 1.1  #1014 Added hasP53Stain question (defaults to yes)
 * 2.0  Expose case information created by LabKit; change version field name.
 * 2.1  The previous case version ID is required when updating a case to prevent clashes.
 */
export const API_VERSION = "2.1";

export interface CustomError {
  error: string;
  msg: string;
  status?: number;
}

// API responses
export interface GetCaseResult {
  micro: IFormContents;
  caseData?: ICaseData;
  caseType: CaseType | null;
  caseVersionId?: string;
  canEditMicro: boolean;
  authorisedMicroReports: AuthorisedMicroReport[];
}

export interface SaveMicroReportResult {
  submittedOk: boolean;
  versionId: string;
}

export type SubmissionType = "AUTOSAVE" | "DRAFT" | "AUTHORISED";

// Filters out reports which were not issued by pathologist authorisation
// of the original micro report or any micro amendments, ie. reports which
// were reissued following a clerical correction and lock in LabKit.
export const originalAndAmendmentsOnly = (
  report: AuthorisedMicroReport,
  index: number,
  array: AuthorisedMicroReport[]
): boolean => {
  return (
    report.reasonForAmendment !== null || // Micro report amendments
    index === array.length - 1 // Always show the original report
  );
};
