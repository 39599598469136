import React from "react";

import { AuthorisedMicroReport } from "../../types/case";
import AuthorisedIndicator from "../atoms/AuthorisedIndicator";
import Accordion from "../common/Accordion";
import ReportSubmission from "./ReportSubmission";

interface PreviousReportsProps {
  authorisedReports: AuthorisedMicroReport[];
}

const PreviousReports = ({ authorisedReports }: PreviousReportsProps): JSX.Element => {
  return (
    <>
      {authorisedReports.map(({ versionId, microHtml, publicationTimestamp }, index) => {
        const isOriginalReport = index === authorisedReports.length - 1;
        return (
          <Accordion
            key={versionId}
            expanded={index === 0} // Expand only the most recent report
            id={`microReport${versionId}`}
            title={isOriginalReport ? "Micro report" : "Micro report amendment"}
            addon={
              <AuthorisedIndicator
                reportSubmitted={publicationTimestamp}
                versionId={versionId}
              />
            }
          >
            <ReportSubmission microHtml={microHtml} versionId={versionId} />
          </Accordion>
        );
      })}
    </>
  );
};

export default PreviousReports;
