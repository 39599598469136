import { faUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { ChangeEvent } from "react";

import { isValidPragueClassification } from "../../helpers/validators";
import {
  AdequateSample,
  ColumnarAtypia,
  ColumnarGroups,
  ColumnarP53Staining,
  InadequateSampleReason,
  KnownBarretts,
  OtherAbnormalities,
  P53Stain,
  ReasonForAmendment,
  SquamousAbnormality,
  SquamousAtypia,
  SquamousP53Staining,
  Tff3Staining,
} from "../../types/answers";
import {
  IFormContents,
  IQuestion,
  IQuestionGroup,
  QuestionGroupId,
  QuestionGroupType,
} from "../../types/builder";
import { AuthorisedMicroReport, CaseType, ICaseData } from "../../types/case";
import AutosaveIndicator, { AutosaveStatus } from "../atoms/AutosaveIndicator";
import ReadyIndicator from "../atoms/ReadyIndicator";
import Accordion from "../common/Accordion";
import PatientDetails from "../patient/PatientDetails";
import PreviousReports from "../report/PreviousReports";
import ReportPreview from "../report/ReportPreview";
import Fieldset from "./Fieldset";
import {
  PRAGUE_CLASSIFICATION_NOT_PROVIDED,
  SCANT_COLUMNAR_EPITHELIUM_THRESHOLD,
} from "./PragueClassification";
import { MORE_THAN_TEN } from "./Tff3PositiveGroups";

export const TEST_ID_QUESTION_GROUPS = "QuestionGroups";
export const TEST_ID_SUBMIT_REPORT_BUTTON = "SubmitReportButton";
export const TEST_ID_INTERACTIVE_MODE = "InteractiveMode";

interface MicroQuestionsProps {
  answers: IFormContents;
  autosaveStatus?: AutosaveStatus;
  caseData?: ICaseData;
  caseType: CaseType | null;
  authorisedReports: AuthorisedMicroReport[];
  isMicroAmendment: boolean;
  setAnswers: (x: IFormContents) => void;
  setShowSaveReportDialog: (x: boolean) => void;
}

const MicroQuestions = ({
  answers,
  autosaveStatus,
  caseData,
  caseType,
  authorisedReports,
  isMicroAmendment,
  setAnswers,
  setShowSaveReportDialog,
}: MicroQuestionsProps): JSX.Element => {
  // Some answers are commonly used to control question visibility and content paths
  const hasBarrettsHistory: boolean = answers.knownBarretts === KnownBarretts.YES;
  const isAdequateSample: boolean = answers.adequateSample === AdequateSample.YES;
  const hasP53Slide: boolean = answers.hasP53Stain === P53Stain.YES;
  const noColumnarGroups: boolean = answers.columnarGroups === ColumnarGroups.NONE;
  const currentCircumferential = parseInt(answers.pragueCircumferential ?? "", 10);
  const currentMaximal = parseInt(answers.pragueMaximal ?? "", 10);
  const pragueClassificationNotProvided: boolean =
    answers.pragueCircumferential === PRAGUE_CLASSIFICATION_NOT_PROVIDED &&
    answers.pragueMaximal === PRAGUE_CLASSIFICATION_NOT_PROVIDED;

  // p53 reporting is required when TFF3 is equivocal/positive or there is any squamous
  // abnormality or columnar atypia. If any of those conditions are met but there is no
  // p53 stain, then the squamous and columnar p53 choices will be visible AND disabled,
  // preventing report completion. This is intentional, as such cases cannot be reported
  // until a p53 stain has been examined by the reporting pathologist.
  const isP53Required: boolean =
    answers.squamousAbnormality === SquamousAbnormality.YES ||
    answers.tff3Staining === Tff3Staining.EQUIVOCAL ||
    answers.tff3Staining === Tff3Staining.POSITIVE ||
    (answers.columnarAtypia !== undefined &&
      answers.columnarAtypia !== ColumnarAtypia.NO);

  // When there is no squamous abnormality, the squamous atypia and squamous p53 choices
  // are hidden, but their underlying values must be set correctly depending on whether
  // or not the sample has a p53 stain.
  const noSquamousAbnormality = (hasP53Stain: boolean = hasP53Slide): IFormContents => {
    return {
      squamousAtypia: SquamousAtypia.NO,
      squamousP53Staining: hasP53Stain ? SquamousP53Staining.NORMAL : undefined,
    };
  };

  // Scant columnar epithelium: when there is a Barrett’s history but only 1-4 columnar
  // groups and C or M is longer than 2cm (or not provided). In these cases the sample
  // is regarded as insufficient for diagnosis. Sometimes this needs calculating during
  // onChange events.
  const scantColumnarEpithelium = (
    circumferential: number = currentCircumferential,
    maximal: number = currentMaximal,
    notProvided: boolean = pragueClassificationNotProvided,
    columnarGroups: ColumnarGroups | undefined = answers.columnarGroups
  ): boolean => {
    return (
      hasBarrettsHistory &&
      columnarGroups === ColumnarGroups.ONETOFOUR &&
      (notProvided ||
        circumferential >= SCANT_COLUMNAR_EPITHELIUM_THRESHOLD ||
        maximal >= SCANT_COLUMNAR_EPITHELIUM_THRESHOLD)
    );
  };

  // This may be called with single values by the Prague classification question
  // onChange handlers, or with both values by ImportPragueClassification.tsx
  const updatePragueClassification = (
    circumferential?: string,
    maximal?: string
  ): void => {
    // Prepare empty object to merge into the micro answers at the end
    const newAnswers: Partial<IFormContents> = {};

    // Hold incoming values as numbers
    const newCircumferential: number = parseInt(circumferential ?? "", 10);
    const newMaximal: number = parseInt(maximal ?? "", 10);

    // If C or M was 'Not provided' then set both to 'Not provided'
    const notProvided: boolean =
      circumferential === PRAGUE_CLASSIFICATION_NOT_PROVIDED ||
      maximal === PRAGUE_CLASSIFICATION_NOT_PROVIDED;
    if (notProvided) {
      newAnswers.pragueCircumferential = PRAGUE_CLASSIFICATION_NOT_PROVIDED;
      newAnswers.pragueMaximal = PRAGUE_CLASSIFICATION_NOT_PROVIDED;
    }
    // C and M
    else if (isValidPragueClassification(newCircumferential, newMaximal)) {
      newAnswers.pragueCircumferential = circumferential;
      newAnswers.pragueMaximal = maximal;
    }
    // C only
    else if (!!circumferential && isNaN(newMaximal)) {
      newAnswers.pragueCircumferential = circumferential;
      // Maximal (M) must be at least as long as Circumferential (C)
      if (!isValidPragueClassification(newCircumferential, currentMaximal)) {
        newAnswers.pragueMaximal = circumferential;
      }
    }
    // M only
    else if (!!maximal && isNaN(newCircumferential)) {
      newAnswers.pragueMaximal = maximal;
      // Circumferential (C) cannot exceed Maximal (M)
      if (!isValidPragueClassification(currentCircumferential, newMaximal)) {
        newAnswers.pragueCircumferential = maximal;
      }
    }

    // Discard columnar p53 and columnar atypia answers if there is scant
    // columnar epithelium
    if (scantColumnarEpithelium(newCircumferential, newMaximal, notProvided)) {
      newAnswers.columnarP53Staining = undefined;
      newAnswers.columnarAtypia = undefined;
    }

    setAnswers({ ...answers, ...newAnswers });
  };

  const questionGroups: IQuestionGroup[] = [
    {
      id: QuestionGroupId.REASON_FOR_AMENDMENT,
      type: QuestionGroupType.RADIO,
      title: "Reason for amendment",
      visible: isMicroAmendment, // Only if there are any previously-authorised reports
      expanded: true,
      questions: [
        {
          name: "reasonForAmendment",
          title: "What is the reason for this amendment?",
          options: [
            {
              label: "Internal audit",
              value: ReasonForAmendment.INTERNAL_AUDIT,
            },
            {
              label: "Customer requested review",
              value: ReasonForAmendment.CUSTOMER_REQUEST,
            },
            {
              label: "New information from customer",
              value: ReasonForAmendment.NEW_INFORMATION_FROM_CUSTOMER,
            },
            {
              label: "None of the above",
              value: ReasonForAmendment.NONE_OF_THE_ABOVE,
            },
          ],
          visible: true,
          selected: answers.reasonForAmendment,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              reasonForAmendment: e.target.value as ReasonForAmendment,
            });
          },
        },
      ],
    },
    {
      id: QuestionGroupId.INITIAL_QUESTIONS,
      type: QuestionGroupType.RADIO,
      title: "Initial questions",
      visible: true,
      expanded: true,
      questions: [
        {
          name: "knownBarretts",
          title: "Does the patient have a history of Barrett’s oesophagus?",
          options: [
            {
              label: "No known history",
              value: KnownBarretts.NO,
            },
            {
              label: "Yes",
              value: KnownBarretts.YES,
            },
          ],
          visible: true,
          selected: answers.knownBarretts,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as KnownBarretts;
            setAnswers({
              ...answers,
              knownBarretts: newValue,
              // Barrett’s surveillance (TRF-2) cases always have a p53 stain
              ...(newValue === KnownBarretts.YES && {
                hasP53Stain: P53Stain.YES,
              }),
              // Set normal underlying squamous atypia and squamous p53 answers
              // when patient has Barrett’s history with no squamous abnormality
              ...(newValue === KnownBarretts.YES &&
                answers.squamousAbnormality === SquamousAbnormality.NO &&
                noSquamousAbnormality(true)),
              pragueCircumferential: "",
              pragueMaximal: "",
            });
          },
        },
        {
          name: "adequateSample",
          title: "Is the overall sample adequate for diagnosis?",
          options: [
            {
              label: "Yes",
              value: AdequateSample.YES,
            },
            {
              label: "No",
              value: AdequateSample.NO,
            },
          ],
          visible: true,
          selected: answers.adequateSample,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as AdequateSample;
            // Reset all but a few answers when overall adequacy changes
            setAnswers({
              reasonForAmendment: answers.reasonForAmendment,
              adequateSample: newValue,
              knownBarretts: answers.knownBarretts,
              hasP53Stain: answers.hasP53Stain,
              pragueCircumferential: "",
              pragueMaximal: "",
              tff3PositiveGroupsSlideSectionOne: "",
              tff3PositiveGroupsSlideSectionTwo: "",
              otherAbnormalities: answers.otherAbnormalities,
            });
          },
        },
        {
          name: "hasP53Stain",
          title: "Has p53 staining been applied to the sample?",
          options: [
            {
              label: "Yes",
              value: P53Stain.YES,
            },
            {
              label: "No",
              // p53 staining is required for known Barrett’s cases
              disabled: hasBarrettsHistory,
              value: P53Stain.NO,
            },
          ],
          visible: isAdequateSample,
          selected: answers.hasP53Stain,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as P53Stain;
            setAnswers({
              ...answers,
              hasP53Stain: newValue,
              // Always discard any squamous p53 answer unless the
              // hidden choices for 'no abnormality' need updating
              // based on whether or not the case has a p53 stain.
              ...(answers.squamousAbnormality === SquamousAbnormality.NO
                ? noSquamousAbnormality(newValue === P53Stain.YES)
                : { squamousP53Staining: undefined }),
              columnarP53Staining: undefined,
            });
          },
        },
        {
          name: "inadequateSampleReason",
          title: "Why is the sample inadequate for diagnosis?",
          options: [
            {
              label: "Insufficient squamous cells",
              value: InadequateSampleReason.INSUFFICIENT,
            },
            {
              label: "Food material contamination",
              value: InadequateSampleReason.FOOD,
            },
          ],
          visible: answers.adequateSample === AdequateSample.NO,
          selected: answers.inadequateSampleReason,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              inadequateSampleReason: e.target.value as InadequateSampleReason,
            });
          },
        },
      ],
    },
    {
      id: QuestionGroupId.PRAGUE_CLASSIFICATION,
      type: QuestionGroupType.RADIO,
      title: "Prague classification",
      visible: hasBarrettsHistory && isAdequateSample,
      expanded: true,
      questions: [
        {
          name: "pragueCircumferential",
          title: "C · Circumferential length (cm)",
          options: [
            // Number picker is added in RadioGroup.tsx
            {
              label: "Not provided",
              value: PRAGUE_CLASSIFICATION_NOT_PROVIDED,
            },
          ],
          visible: true,
          selected: answers.pragueCircumferential,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            updatePragueClassification(e.target.value, undefined);
          },
        },
        {
          name: "pragueMaximal",
          title: "M · Maximal length (cm)",
          options: [
            // Number picker is added in RadioGroup.tsx
            {
              label: "Not provided",
              value: PRAGUE_CLASSIFICATION_NOT_PROVIDED,
            },
          ],
          visible: true,
          selected: answers.pragueMaximal,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            updatePragueClassification(undefined, e.target.value);
          },
        },
      ],
    },
    {
      id: QuestionGroupId.SQUAMOUS_CELLS,
      type: QuestionGroupType.RADIO,
      title: "Squamous cells",
      visible: isAdequateSample,
      expanded: true,
      questions: [
        {
          name: "squamousAbnormality",
          title: "Is there any squamous abnormality?",
          options: [
            {
              label: "No",
              value: SquamousAbnormality.NO,
            },
            {
              label: "Yes",
              value: SquamousAbnormality.YES,
            },
            {
              label: "Inadequate for diagnosis",
              value: SquamousAbnormality.INADEQUATE,
            },
          ],
          visible: true,
          selected: answers.squamousAbnormality,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as SquamousAbnormality;
            setAnswers({
              ...answers,
              squamousAbnormality: newValue,
              // When 'No' squamous abnormality is checked, set 'No' for
              // squamous atypia and 'Normal' for squamous p53 (only if
              // there is a p53 stain) otherwise reset those two questions.
              ...(newValue === SquamousAbnormality.NO
                ? noSquamousAbnormality()
                : {
                    squamousAtypia: undefined,
                    squamousP53Staining: undefined,
                  }),
              // Don't allow inadequate squamous cellularity AND no columnar
              // groups, because in that scenario the overall sample should
              // have been deemed inadequate.
              ...(newValue === SquamousAbnormality.INADEQUATE &&
                answers.columnarGroups === ColumnarGroups.NONE && {
                  columnarGroups: undefined,
                }),
            });
          },
        },
        {
          name: "squamousAtypia",
          title: "Squamous atypia",
          options: [
            {
              label: "No",
              value: SquamousAtypia.NO,
            },
            {
              label: "Uncertain significance",
              value: SquamousAtypia.UNCERTAIN_SIGNIFICANCE,
            },
            {
              label: "Dysplasia is suspected",
              value: SquamousAtypia.SUSPECTED_DYSPLASIA,
            },
          ],
          visible: answers.squamousAbnormality === SquamousAbnormality.YES,
          selected: answers.squamousAtypia,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as SquamousAtypia;
            setAnswers({
              ...answers,
              squamousAtypia: newValue,
              // Uncheck 'Normal' for squamous p53 when 'No' was selected for
              // squamous atypia, because both cannot be checked if 'Yes' was
              // selected for squamous abnormality.
              ...(newValue === SquamousAtypia.NO &&
                answers.squamousP53Staining === SquamousP53Staining.NORMAL && {
                  squamousP53Staining: undefined,
                }),
            });
          },
        },
        {
          name: "squamousP53Staining",
          title: "p53 stain in squamous cells",
          options: [
            {
              label: "Normal",
              disabled: !hasP53Slide,
              value: SquamousP53Staining.NORMAL,
            },
            {
              label: "Equivocal",
              disabled: !hasP53Slide,
              value: SquamousP53Staining.EQUIVOCAL,
            },
            {
              label: "Aberrant",
              disabled: !hasP53Slide,
              value: SquamousP53Staining.ABERRANT,
            },
          ],
          visible: answers.squamousAbnormality === SquamousAbnormality.YES,
          selected: answers.squamousP53Staining,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as SquamousP53Staining;
            setAnswers({
              ...answers,
              squamousP53Staining: newValue,
              // Uncheck 'No' for squamous atypia when 'Normal' was selected
              // for squamous p53, because both cannot be checked if 'Yes' was
              // selected for squamous abnormality.
              ...(newValue === SquamousP53Staining.NORMAL &&
                answers.squamousAtypia === SquamousAtypia.NO && {
                  squamousAtypia: undefined,
                }),
            });
          },
        },
      ],
    },
    {
      id: QuestionGroupId.COLUMNAR_CELLS,
      type: QuestionGroupType.RADIO,
      title: "Columnar cells",
      visible: isAdequateSample,
      expanded: true,
      questions: [
        {
          name: "columnarGroups",
          title: "Number of columnar cell groups / clumps",
          options: [
            {
              label: "5+",
              value: ColumnarGroups.FIVEORMORE,
            },
            {
              label: "1-4",
              value: ColumnarGroups.ONETOFOUR,
            },
            {
              label: "None",
              value: ColumnarGroups.NONE,
              hidden: answers.squamousAbnormality === SquamousAbnormality.INADEQUATE,
            },
          ],
          visible: true,
          selected: answers.columnarGroups,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const newValue = e.target.value as ColumnarGroups;
            setAnswers({
              ...answers,
              columnarGroups: newValue,
              ...(newValue === ColumnarGroups.NONE && {
                tff3Staining: undefined,
                tff3PositiveGroupsSlideSectionOne: "",
                tff3PositiveGroupsSlideSectionTwo: "",
                columnarP53Staining: undefined,
                columnarAtypia: undefined,
              }),
              // Discard columnar p53 and columnar atypia answers for known Barrett’s cases
              // with 1-4 columnar groups (where C or M is longer than 2cm or not provided)
              // because in those cases the sample is insufficient for diagnosis.
              ...(scantColumnarEpithelium(undefined, undefined, undefined, newValue) && {
                columnarP53Staining: undefined,
                columnarAtypia: undefined,
              }),
            });
          },
        },
        {
          name: "tff3Staining",
          title: "TFF3 stain in columnar cells",
          options: [
            {
              label: "Negative",
              value: Tff3Staining.NEGATIVE,
            },
            {
              label: "Equivocal",
              value: Tff3Staining.EQUIVOCAL,
            },
            {
              label: "Positive",
              value: Tff3Staining.POSITIVE,
            },
          ],
          visible: !noColumnarGroups,
          selected: answers.tff3Staining,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              tff3Staining: e.target.value as Tff3Staining,
              // Only required when TFF3 is positive
              tff3PositiveGroupsSlideSectionOne: "",
              tff3PositiveGroupsSlideSectionTwo: "",
            });
          },
        },
        {
          name: "tff3PositiveGroupsSlideSectionOne",
          title: "TFF3 positive groups per slide (Section 1)",
          options: [
            // Number picker is added in RadioGroup.tsx
            {
              label: "More than 10",
              value: MORE_THAN_TEN,
            },
          ],
          visible: answers.tff3Staining === Tff3Staining.POSITIVE,
          selected: answers.tff3PositiveGroupsSlideSectionOne,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              tff3PositiveGroupsSlideSectionOne: e.target.value as string,
            });
          },
        },
        {
          name: "tff3PositiveGroupsSlideSectionTwo",
          title: "TFF3 positive groups per slide (Section 2)",
          options: [
            // Number picker is added in RadioGroup.tsx
            {
              label: "More than 10",
              value: MORE_THAN_TEN,
            },
          ],
          visible: answers.tff3Staining === Tff3Staining.POSITIVE,
          selected: answers.tff3PositiveGroupsSlideSectionTwo,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              tff3PositiveGroupsSlideSectionTwo: e.target.value as string,
            });
          },
        },
        {
          name: "columnarAtypia",
          title: "Columnar atypia",
          options: [
            {
              label: "None",
              value: ColumnarAtypia.NO,
            },
            {
              label: "Uncertain significance",
              value: ColumnarAtypia.UNCERTAIN_SIGNIFICANCE,
            },
            {
              label: "Dysplasia suspected but difficult to grade",
              value: ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE,
            },
            {
              label: "Suspected high grade dysplasia",
              value: ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA,
            },
            {
              label: "High grade dysplasia",
              value: ColumnarAtypia.HIGH_GRADE_DYSPLASIA,
            },
            {
              label: "At least high grade dysplasia",
              value: ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA,
            },
          ],
          visible: !noColumnarGroups && !scantColumnarEpithelium(),
          selected: answers.columnarAtypia,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              columnarAtypia: e.target.value as ColumnarAtypia,
            });
          },
        },
        {
          name: "columnarP53Staining",
          title: "p53 stain in columnar cells",
          options: [
            {
              label: "Normal",
              disabled: !hasP53Slide,
              value: ColumnarP53Staining.NORMAL,
            },
            {
              label: "Equivocal",
              disabled: !hasP53Slide,
              value: ColumnarP53Staining.EQUIVOCAL,
            },
            {
              label: "Aberrant",
              disabled: !hasP53Slide,
              value: ColumnarP53Staining.ABERRANT,
            },
          ],
          visible:
            // Even when there is no p53 stain, show this question when the case
            // requires a p53 stain to been examined by the pathologist. In this
            // case the choices will be disabled, preventing report completion.
            (hasP53Slide || isP53Required) &&
            !noColumnarGroups &&
            !scantColumnarEpithelium(),
          selected: answers.columnarP53Staining,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            setAnswers({
              ...answers,
              columnarP53Staining: e.target.value as ColumnarP53Staining,
            });
          },
        },
      ],
    },
    {
      id: QuestionGroupId.OTHER_ABNORMALITIES,
      type: QuestionGroupType.CHECKBOX,
      title: "Other abnormalities",
      visible: true,
      expanded: !!answers.otherAbnormalities?.length,
      questions: [
        {
          name: "otherAbnormalities",
          title: "",
          options: [
            {
              label: "Evidence of ulceration",
              value: OtherAbnormalities.ULCERATION,
            },
          ],
          visible: true,
          optional: true,
          selected: answers.otherAbnormalities,
          onChange: (e: ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value as OtherAbnormalities;
            const prevState = answers.otherAbnormalities ?? [];
            let newState = [...prevState];
            if (e.target.checked) {
              newState = [...prevState, value];
            } else {
              newState.splice(prevState.indexOf(value), 1);
            }
            setAnswers({
              ...answers,
              otherAbnormalities: newState,
            });
          },
        },
      ],
    },
  ];

  const visibleQuestionGroups: IQuestionGroup[] = questionGroups.filter(
    (group) => group.visible
  );

  const visibleQuestions: IQuestion[] = visibleQuestionGroups.flatMap((group) =>
    group.questions.filter((question) => question.visible)
  );

  const isEveryRequiredQuestionAnswered: boolean = visibleQuestions
    .filter((question) => !question.optional)
    .every((question) => !!question.selected);

  // Only allow 'Submit report' dialog to be opened when all questions have
  // been answered and autosaving has finished (or in test mode)
  const isSubmitReportButtonEnabled: boolean =
    isEveryRequiredQuestionAnswered &&
    [AutosaveStatus.SAVED, undefined].includes(autosaveStatus);

  return (
    <div
      className="columns is-desktop is-align-items-flex-start"
      data-testid={TEST_ID_INTERACTIVE_MODE}
    >
      <div className="column is-two-fifths-desktop" data-testid={TEST_ID_QUESTION_GROUPS}>
        {visibleQuestionGroups.map((group) => {
          return (
            <Fieldset
              key={group.id}
              id={group.id}
              type={group.type}
              legend={group.title}
              expanded={group.expanded}
              caseData={caseData}
              questions={group.questions}
              answers={answers}
              updatePragueClassification={updatePragueClassification}
            />
          );
        })}
      </div>
      <div
        className="column is-three-fifths-desktop"
        style={{ top: 0, position: "sticky" }}
      >
        <PatientDetails expanded caseData={caseData} caseType={caseType} />
        <Accordion
          title={isMicroAmendment ? "Micro report amendment" : "Micro report"}
          id="microReport"
          expanded
          addon={
            <div className="tags">
              <ReadyIndicator isReady={isEveryRequiredQuestionAnswered} />
              <AutosaveIndicator status={autosaveStatus} />
            </div>
          }
        >
          <ReportPreview
            answers={answers}
            isMicroAmendment={isMicroAmendment}
            previousReport={authorisedReports[0]}
          />
        </Accordion>
        <PreviousReports authorisedReports={authorisedReports} />
        <button
          className="button is-primary is-large is-fullwidth"
          disabled={!isSubmitReportButtonEnabled}
          data-testid={TEST_ID_SUBMIT_REPORT_BUTTON}
          onClick={() => setShowSaveReportDialog(true)}
        >
          <FontAwesomeIcon icon={faUpRightFromSquare} className="mr-3" />
          Submit report
        </button>
      </div>
    </div>
  );
};

export default MicroQuestions;
