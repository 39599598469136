import { intersection, uniqBy } from "lodash";

import {
  LONG_SEGMENT_CIRCUMFERENTIAL_THRESHOLD,
  LONG_SEGMENT_MAXIMAL_THRESHOLD,
  MAX_PRAGUE_MEASUREMENT,
  PRAGUE_CLASSIFICATION_NOT_PROVIDED,
  SCANT_COLUMNAR_EPITHELIUM_THRESHOLD,
} from "../components/questions/PragueClassification";
import { getNumberArray } from "../helpers/numbers";
import {
  AdequateSample,
  ColumnarAtypia,
  ColumnarGroups,
  ColumnarP53Staining,
  KnownBarretts,
  OtherAbnormalities,
  P53Stain,
  SquamousAbnormality,
  SquamousAtypia,
  SquamousP53Staining,
  Tff3Staining,
} from "../types/answers";
import { IConditionalContent, IFormContents } from "../types/builder";

export interface SnomedCode {
  description: string;
  code: string;
}

type ConditionalSnomedCode = SnomedCode & IConditionalContent;

export const SnomedCodes: { [key: string]: SnomedCode } = {
  ENTIRE_OESOPHAGUS: {
    description: "Entire oesophagus",
    code: "181245004",
  },
  COLLECTION_OF_OESOPHAGEAL_CELLS: {
    description:
      "Collection of oesophageal cells via swallowable cell collection sponge in capsule (procedure)",
    code: "1202027002",
  },
  CYTOLOGY_TECHNIQUE: {
    description: "Cytology technique",
    code: "702666009",
  },
  NORMAL: { description: "Normal", code: "17621005" },
  INADEQUATE: { description: "Inadequate", code: "71978007" },
  INSUFFICIENT: { description: "Insufficient", code: "423437008" },
  SQUAMOUS_CELL_ATYPIA: { description: "Squamous cell atypia", code: "69740004" },
  DYSPLASIA: { description: "Dysplasia", code: "25723000" },
  EQUIVOCAL_RESULT: { description: "Equivocal result", code: "280414007" },
  INTESTINAL_METAPLASIA: { description: "Intestinal metaplasia", code: "69310004" },
  CYTOLOGIC_ATYPIA: { description: "Cytologic atypia", code: "50673007" },
  ULCER: { description: "Ulcer", code: "56208002" },
};

// Default SNOMED codes are unconditional and are included in every report
const defaultSnomedCodes: SnomedCode[] = [
  SnomedCodes.ENTIRE_OESOPHAGUS,
  SnomedCodes.COLLECTION_OF_OESOPHAGEAL_CELLS,
  SnomedCodes.CYTOLOGY_TECHNIQUE,
];

// Conditional SNOMED codes depend on the user's answers
const conditionalSnomedCodes: ConditionalSnomedCode[] = [
  {
    ...SnomedCodes.NORMAL,
    conditions: {
      hasP53Stain: [P53Stain.NO],
      squamousAbnormality: [SquamousAbnormality.NO],
      tff3Staining: [Tff3Staining.NEGATIVE],
      columnarAtypia: [ColumnarAtypia.NO],
    },
  },
  {
    ...SnomedCodes.NORMAL,
    conditions: {
      hasP53Stain: [P53Stain.YES],
      squamousAbnormality: [SquamousAbnormality.NO],
      tff3Staining: [Tff3Staining.NEGATIVE],
      columnarAtypia: [ColumnarAtypia.NO],
      columnarP53Staining: [ColumnarP53Staining.NORMAL],
    },
  },
  {
    ...SnomedCodes.INADEQUATE,
    conditions: {
      adequateSample: [AdequateSample.NO],
    },
  },
  {
    ...SnomedCodes.INADEQUATE,
    conditions: {
      squamousAbnormality: [SquamousAbnormality.INADEQUATE],
    },
  },
  {
    ...SnomedCodes.INSUFFICIENT,
    conditions: {
      columnarGroups: [ColumnarGroups.NONE],
    },
  },
  {
    ...SnomedCodes.INSUFFICIENT,
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarGroups: [ColumnarGroups.ONETOFOUR],
      pragueCircumferential: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(SCANT_COLUMNAR_EPITHELIUM_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
    },
  },
  {
    ...SnomedCodes.INSUFFICIENT,
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      columnarGroups: [ColumnarGroups.ONETOFOUR],
      pragueMaximal: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(SCANT_COLUMNAR_EPITHELIUM_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
    },
  },
  {
    ...SnomedCodes.INSUFFICIENT,
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      pragueCircumferential: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(LONG_SEGMENT_CIRCUMFERENTIAL_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
      columnarGroups: [ColumnarGroups.FIVEORMORE],
      tff3Staining: [Tff3Staining.NEGATIVE, Tff3Staining.EQUIVOCAL],
    },
  },
  {
    ...SnomedCodes.INSUFFICIENT,
    conditions: {
      knownBarretts: [KnownBarretts.YES],
      pragueMaximal: [
        PRAGUE_CLASSIFICATION_NOT_PROVIDED,
        ...getNumberArray(LONG_SEGMENT_MAXIMAL_THRESHOLD, MAX_PRAGUE_MEASUREMENT),
      ],
      columnarGroups: [ColumnarGroups.FIVEORMORE],
      tff3Staining: [Tff3Staining.NEGATIVE, Tff3Staining.EQUIVOCAL],
    },
  },
  {
    ...SnomedCodes.SQUAMOUS_CELL_ATYPIA,
    conditions: {
      squamousAtypia: [
        SquamousAtypia.UNCERTAIN_SIGNIFICANCE,
        SquamousAtypia.SUSPECTED_DYSPLASIA,
      ],
    },
  },
  {
    ...SnomedCodes.DYSPLASIA,
    conditions: {
      squamousAtypia: [SquamousAtypia.SUSPECTED_DYSPLASIA],
    },
  },
  {
    ...SnomedCodes.EQUIVOCAL_RESULT,
    conditions: {
      squamousP53Staining: [SquamousP53Staining.EQUIVOCAL],
    },
  },
  {
    ...SnomedCodes.EQUIVOCAL_RESULT,
    conditions: {
      tff3Staining: [Tff3Staining.EQUIVOCAL],
    },
  },
  {
    ...SnomedCodes.EQUIVOCAL_RESULT,
    conditions: {
      columnarP53Staining: [ColumnarP53Staining.EQUIVOCAL],
    },
  },
  {
    ...SnomedCodes.INTESTINAL_METAPLASIA,
    conditions: {
      tff3Staining: [Tff3Staining.POSITIVE],
    },
  },
  {
    ...SnomedCodes.CYTOLOGIC_ATYPIA,
    conditions: {
      columnarAtypia: [ColumnarAtypia.UNCERTAIN_SIGNIFICANCE],
    },
  },
  {
    ...SnomedCodes.DYSPLASIA,
    conditions: {
      columnarAtypia: [
        ColumnarAtypia.SUSPECTED_DYSPLASIA_DIFFICULT_TO_GRADE,
        ColumnarAtypia.SUSPECTED_HIGH_GRADE_DYSPLASIA,
        ColumnarAtypia.HIGH_GRADE_DYSPLASIA,
        ColumnarAtypia.AT_LEAST_HIGH_GRADE_DYSPLASIA,
      ],
    },
  },
  {
    ...SnomedCodes.ULCER,
    conditions: {
      otherAbnormalities: [OtherAbnormalities.ULCERATION],
    },
  },
];

const shouldOmitNormalSnomedCode = (
  code: ConditionalSnomedCode,
  answers: IFormContents
): boolean => {
  return (
    code.description === SnomedCodes.NORMAL.description &&
    !!answers.otherAbnormalities?.length
  );
};

//
const getConditionalSnomedCodes = (answers: IFormContents): ConditionalSnomedCode[] => {
  const matchedSnomedCodes = conditionalSnomedCodes.filter((code) =>
    Object.entries(code.conditions).every(([key, condition]) => {
      const answer = answers[key as keyof typeof answers];
      if (Array.isArray(answer)) {
        return !!intersection(answer, condition).length; // Other abnormalities
      } else if (shouldOmitNormalSnomedCode(code, answers)) {
        return false; // Omit 'Normal' code if there are any other abnormalities
      } else {
        return condition?.includes(answer) ?? false; // Everything else
      }
    })
  );
  // Remove any duplicates, eg. squamous and columnar dysplasia should only
  // include 'Dysplasia' SNOMED code once
  return uniqBy(matchedSnomedCodes, "code");
};

const getReportSnomedCodes = (answers: IFormContents): SnomedCode[] => {
  const matchedSnomedCodes = getConditionalSnomedCodes(answers);
  return [...defaultSnomedCodes, ...matchedSnomedCodes];
};

const getCommaSeparatedSnomedCodes = (answers: IFormContents): string => {
  const reportSnomedCodes = getReportSnomedCodes(answers);
  return reportSnomedCodes.map((c) => c.code).join(",");
};

export { getReportSnomedCodes, getCommaSeparatedSnomedCodes };
