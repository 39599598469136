import React from "react";

import { getManagementRecommendation } from "../../rules/Recommendation";
import { IFormContents } from "../../types/builder";
import Placeholder from "./Placeholder";
import SectionHeading from "./SectionHeading";
import SectionSpacer from "./SectionSpacer";

export const TEST_ID_REPORT_PREVIEW_RECOMMENDATION = "ReportPreviewRecommendation";

interface RecommendationProps {
  answers: IFormContents;
}

const Recommendation = ({ answers }: RecommendationProps): JSX.Element => {
  const recommendationPhrase = getManagementRecommendation(answers);

  return (
    <section data-testid={TEST_ID_REPORT_PREVIEW_RECOMMENDATION}>
      <SectionHeading>Recommendation</SectionHeading>
      {recommendationPhrase ? <p>{recommendationPhrase}</p> : <Placeholder />}
      <SectionSpacer />
    </section>
  );
};

export default Recommendation;
