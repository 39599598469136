import React from "react";

interface PageHeaderProps {
  title: string;
  subtitle?: string;
}

const PageHeader = ({ title, subtitle }: PageHeaderProps): JSX.Element => {
  return (
    <header className="hero">
      <div className="hero-body px-0">
        <h1 className="title">{title}</h1>
        {!!subtitle && <h2 className="subtitle">{subtitle}</h2>}
      </div>
    </header>
  );
};

export default PageHeader;
