import { faRightFromBracket, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import logo from "../../assets/Cyted-Logo-RGB_White.png";
import { handleSignOut } from "../../helpers/auth";
import { RootState } from "../../store";

const Navbar = (): JSX.Element => {
  const { currentUser } = useSelector((state: RootState) => state.auth);
  const username = currentUser?.username;
  return (
    <nav role="navigation" className="navbar is-black" aria-label="main navigation">
      <div className="navbar-brand">
        <div className="navbar-item">
          <img src={logo} alt="Cyted logo" />
        </div>
      </div>
      <div className="navbar-menu">
        <div className="navbar-start is-hidden-mobile">
          {/* Intentional page reload to clear any query string parameter */}
          <a href="/" className="navbar-item">
            PathKit
          </a>
        </div>
        {!!username && (
          <div className="navbar-end">
            <Link to="profile" className="navbar-item">
              <FontAwesomeIcon icon={faUser} size="sm" className="mr-2" />
              {username}
            </Link>
            <a href="" className="navbar-item" onClick={handleSignOut}>
              <FontAwesomeIcon icon={faRightFromBracket} className="mr-2" />
              Log out
            </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
