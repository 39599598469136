import classNames from "classnames";
import React, { ChangeEvent } from "react";

interface ChoiceProps {
  type: "radio" | "checkbox";
  label?: string;
  value: string;
  name: string;
  checked: boolean;
  disabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const Choice = ({
  type,
  label,
  value,
  name,
  checked,
  disabled,
  onChange,
}: ChoiceProps): JSX.Element => {
  const classes = classNames({
    "cy-choice": true,
    "cy-choice--checked": checked,
    "cy-choice--disabled": disabled,
  });

  return (
    <div className={classes}>
      <label className="cy-choice__label">
        <input
          type={type}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          className="cy-choice__input"
        />
        {label ?? value}
      </label>
    </div>
  );
};

export default Choice;
