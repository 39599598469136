import React from "react";

import { IFormContents, IQuestion } from "../../types/builder";
import NumberPicker from "./NumberPicker";

interface PragueClassificationProps {
  question: IQuestion;
  answers: IFormContents;
}

export const SCANT_COLUMNAR_EPITHELIUM_THRESHOLD = 3;
export const LONG_SEGMENT_CIRCUMFERENTIAL_THRESHOLD = 4;
export const LONG_SEGMENT_MAXIMAL_THRESHOLD = 6;
export const MAX_PRAGUE_MEASUREMENT = 20;
export const PRAGUE_CLASSIFICATION_NOT_PROVIDED = "PragueClassificationNotProvided";

const PragueClassification = ({
  question,
  answers,
}: PragueClassificationProps): JSX.Element | null => {
  if (question.name === "pragueCircumferential") {
    return (
      <NumberPicker
        id={question.name}
        min={0}
        max={MAX_PRAGUE_MEASUREMENT}
        value={answers.pragueCircumferential ?? ""}
        onChange={question.onChange}
      />
    );
  }
  if (question.name === "pragueMaximal") {
    return (
      <NumberPicker
        id={question.name}
        min={0}
        max={MAX_PRAGUE_MEASUREMENT}
        value={answers.pragueMaximal ?? ""}
        onChange={question.onChange}
      />
    );
  }
  return null;
};

export default PragueClassification;
