import { faTurnDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MouseEvent } from "react";

import { isValidPragueClassification } from "../../helpers/validators";

export const TEST_ID_IMPORT_MEASUREMENTS_LINK = "ImportMeasurementsLink";

interface ImportPragueClassificationProps {
  circumferential?: number;
  maximal?: number;
  updatePragueClassification: (c?: string, m?: string) => void;
}

const ImportPragueClassification = ({
  circumferential,
  maximal,
  updatePragueClassification,
}: ImportPragueClassificationProps): JSX.Element | null => {
  const handleClick = (e: MouseEvent) => {
    e.preventDefault();
    updatePragueClassification(circumferential?.toString(), maximal?.toString());
  };

  if (!isValidPragueClassification(circumferential, maximal)) return null;

  return (
    <a
      href=""
      role="button"
      className="is-relative has-text-weight-semibold"
      style={{ left: 33, top: -18 }}
      data-testid={TEST_ID_IMPORT_MEASUREMENTS_LINK}
      onClick={handleClick}
    >
      <FontAwesomeIcon icon={faTurnDown} className="mr-2" />
      Import patient measurements
    </a>
  );
};

export default ImportPragueClassification;
